/**
    This is the top-level component for the entire app. The component element contains the current view of the app. 
    Global modals (those that can be called from any view) are stored here.
*/
<template>
    <div id="app-container">
        <Navbar />
        <NotificationBanner v-if="showBanner && this.store.state.chosenSchool === 'easthartford_ciba_36'" message="We're working hard on fixing CIBA's data connection. The information you see is current as of 9/4." />
        <div class="app-main-area">
            <EditDataModal />
            <TrainingModal />
            <SecurityModal />
            <IdleModal />
            <ChangeModeModal />
            <ActivitiesModal />
            <TagManager />

            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component ref="currentRoute" :is="Component" />
                </keep-alive>
            </router-view>
            
        </div>
    </div>
</template>

<script>
import TagManager from '@/components/modals/tagManager.vue';
import ActivitiesModal from '@/components/modals/activitiesModal.vue';
import EditDataModal from '@/components/modals/editDataModal.vue';
import ChangeModeModal from '@/components/modals/changeModeModal.vue';
import IdleModal from '@/components/modals/idleModal.vue';
import TrainingModal from '@/components/modals/trainingModal.vue';
import SecurityModal from '@/components/modals/securityModal.vue';
import Navbar from '@/components/nav/navbar.vue';
import NotificationBanner from '@/components/notificationBanner.vue';

export default {
    components: {
        Navbar, TagManager, ActivitiesModal, EditDataModal, ChangeModeModal, IdleModal, TrainingModal, SecurityModal, NotificationBanner
    },
    inject: ["posthog"],
    data() {
        return {
            showBanner: false
        }
    },
    mounted() {
        this.posthog.onFeatureFlags(() => {
            if (this.posthog.isFeatureEnabled('ciba-stale')) {
                this.showBanner = true;
            }
        })
    }
}
</script>

<style lang="scss">
    @import 'assets/styles/mystyles.scss';
</style>
